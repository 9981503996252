<template>
	<div class="container order-details">
		<div class="row">
			<div class="col-md-10 text-center middle">
				<h3 class="line-lg">Your order has been successfully submitted.</h3>
				<p><strong>While we are processing your order, you should receive an email confirming your purchase.</strong><br><br>Please print the information below for your records.</p>
			</div>

			<div class="col-md-6">
				<div class="order-details__box">
					<h4>Items</h4>
					<ul class="row align-items-center" v-for="(product, key) in order.products" :key="key">
						<li class="col col-1 strong">{{product.quantity}}x</li>
						<li class="col strong">{{product.name}}</li>
						<li class="col">{{product.price | currency}}</li>
						<li class="col"><h5>{{(product.quantity * product.price) | currency}}</h5></li>
						<li><hr /></li>
					</ul><br>
				</div>
			</div>

			<div class="col-md-6">
				<div class="order-details__box">
					<h4>Order Details</h4>
					<dl class="dl-horizontal row">
						<dt class="col-4">Order ID</dt>
						<dd class="col-8">{{order.$key | idMask('IK')}}</dd>
						<dt class="col-4">Date</dt>
						<dd class="col-8">{{order.created_at | date}}</dd>
						<dt class="col-4">Subtotal</dt>
						<dd class="col-8">{{order.subTotal | currency}}</dd>
						<dt class="col-4">Taxes</dt>
						<dd class="col-8">{{order.taxes || 0 | currency}}</dd>
						<dt class="col-4">Shipping</dt>
						<dd class="col-8">{{order.shipping.method.price | currency}}</dd>
						<dt class="col-4">Total</dt>
						<dd class="col-8">{{order.total | currency}}</dd>
					</dl><br>

					<h4>Charge</h4>
					<dl class="dl-horizontal row">
						<dt class="col-4">Name</dt>
						<dd class="col-8">{{order.charge.source.name}}</dd>
						<dt class="col-4">Type</dt>
						<dd class="col-8">{{order.charge.source.brand}}</dd>
						<dt class="col-4">Last 4</dt>
						<dd class="col-8">{{order.charge.source.last4}}</dd>
						<dt class="col-4">Exp. Year</dt>
						<dd class="col-8">{{order.charge.source.exp_year}}</dd>
					</dl>

					<h4>Shipping</h4>
					<dl class="dl-horizontal row">
						<dt class="col-4">Contact</dt>
						<dd class="col-8">{{order.shipping.fullName}}</dd>
						<dt class="col-4">Business</dt>
						<dd class="col-8">{{order.shipping.company}}</dd>
						<dt class="col-4">Address</dt>
						<dd class="col-8">
							{{order.shipping.street1}} {{order.shipping.street2}}<br>
							{{order.shipping.city}}, {{order.shipping.state}} {{order.shipping.zip}}
						</dd>
						<dt class="col-4">Method</dt>
						<dd class="col-8">
							{{order.shipping.method.carrier}}<br>
							{{order.shipping.method.delivery}}
						</dd>
					</dl>
				</div>
			</div>
		</div>
		<noscript>
		<div style="display:inline;">
			<img height="1" width="1" style="border-style:none;" alt="" :src="`//www.googleadservices.com/pagead/conversion/938188437/?value=${order.total}&amp;currency_code=USD&amp;label=4LvCCJaLumMQlb2uvwM&amp;guid=ON&amp;script=0`"/>
		</div>
		</noscript>
	</div>
</template>

<script>
// Dependencies
import { track } from '@/lib/analytics'

// Component
export default {
	name: 'order',
	computed: {
		loading() {
			return this.$store.getters['cart/loading']
		},
		error() {
			return this.$store.getters['cart/error']
		},
		order() {
			return this.$store.getters['cart/order']
		},
	},
	mounted() {
		track('ec:setActions', 'purchase', {
			id: this.order.$key,
			revenue: this.order.subTotal,
			tax: this.order.taxes,
			shipping: this.order.shipping,
		})
		setTimeout(this.pageAdConversion, 500)
	},
	methods: {
		pageAdConversion() {
			const scriptId = 'gpac'
			if (document.getElementById(scriptId)) return
			window.google_conversion_id = process.env.VUE_APP_GOOGLE_CONVERSION_ID
			window.google_conversion_language = 'en'
			window.google_conversion_format = 3
			window.google_conversion_color = 'ffffff'
			window.google_conversion_label = '4LvCCJaLumMQlb2uvwM'
			window.google_conversion_value = this.order.total
			window.google_conversion_currency = 'USD'
			window.google_remarketing_only = false

			const script = document.createElement('script')
			script.setAttribute('src', '//www.googleadservices.com/pagead/conversion.js')
			script.setAttribute('id', scriptId)
			document.body.append(script)
		},
	},
}
</script>

<style scoped lang="scss">
.order-details {
	&__box {
		box-shadow: $box-shadows;
		padding: 0 0 20px;
		background-color: color_('white');

		h4 {
			margin: 0;
			padding: 20px 0;
			text-align: center;
			border-bottom: 1px solid color_('gray','base');
			margin-bottom: 20px;
			background-color: #eee;
			border-bottom: 1px solid #ccc;
		}

		.dl-horizontal {
			margin-right: 0;
			margin-left: 20px;
		}

		ul {
			margin-left: 20px;

			li {
				display: inline;
				padding: 0;
			}
		}

		h5 {
			margin: $line 0;
			font-size: $font-size-h5 * 1.2;
		}
	}
}
</style>
