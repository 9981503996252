/* global ga */
export function track() {
	if (typeof ga !== 'undefined' && typeof ga.getAll === 'function') {
		try {
			const tracker = ga.getAll()[0]
			tracker.send(...arguments)
		} catch (e) {
			console.error(e)
		}
	} else {
		setTimeout(track, 1000, arguments)
	}
}
